<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'createOrder'"
      :class="modalState ? 'show' : ''"
      tabindex="-1"
      role="dialog"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Order</h5>
            <a class="close" @click="$store.dispatch('modalClose')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="addOrder"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-6 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group">
                      <label class="group-label">Company Detail</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 text-right mr-0 pr-0">
                            <label>Company Name</label>
                          </div>
                          <div
                            class="col-md-7"
                            :class="{ invalid: $v.formData.company.$error }"
                          >
                            <input
                              type="text"
                              class="form-control border-radius-0"
                              placeholder="Company Name"
                              @blur="$v.formData.company.$touch()"
                              v-model.trim="formData.company"
                              required
                            />
                          </div>
                          <div
                            class="col-md-12 text-right"
                            v-if="$v.formData.company.$error"
                          >
                            <p
                              class="error_level"
                              v-if="!$v.formData.company.required"
                            >
                              Company Name field is required.
                            </p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.company.minLength"
                            >
                              At least 2 character company name.
                            </p>
                          </div>
                        </div>
                        <div class="row pb-2">
                          <div class="col-md-5 text-right mr-0 pr-0">
                            <label>PAN / VAT Number</label>
                          </div>
                          <div
                            class="col-md-7"
                            :class="{ invalid: $v.formData.pan.$error }"
                          >
                            <input
                              type="number"
                              class="form-control border-radius-0"
                              placeholder="PAN / VAT Number"
                              @blur="$v.formData.pan.$touch()"
                              v-model.trim="formData.pan"
                              required
                            />
                          </div>
                          <div
                            class="col-md-12 text-right"
                            v-if="$v.formData.pan.$error"
                          >
                            <p
                              class="error_level"
                              v-if="!$v.formData.pan.required"
                            >
                              PAN/Registration field is required.
                            </p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.pan.minLength"
                            >
                              9 character PAN/VAT number.
                            </p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.pan.maxLength"
                            >
                              9 character PAN/VAT number.
                            </p>
                          </div>
                        </div>
                        <div class="row pb-2">
                          <div class="col-md-5 text-right mr-0 pr-0">
                            <label>Company Address</label>
                          </div>
                          <div
                            class="col-md-7"
                            :class="{ invalid: $v.formData.address.$error }"
                          >
                            <input
                              type="text"
                              class="form-control border-radius-0"
                              placeholder="Company Address"
                              @blur="$v.formData.address.$touch()"
                              v-model.trim="formData.address"
                              required
                            />
                          </div>
                          <div
                            class="col-md-12 text-right"
                            v-if="$v.formData.address.$error"
                          >
                            <p
                              class="error_level"
                              v-if="!$v.formData.address.required"
                            >
                              Address field is required.
                            </p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.address.minLength"
                            >
                              At least 2 character address.
                            </p>
                          </div>
                        </div>
                        <div class="row pb-2">
                          <div class="col-md-5 text-right mr-0 pr-0">
                            <label>Company Email</label>
                          </div>
                          <div
                            class="col-md-7"
                            :class="{ invalid: $v.formData.email.$error }"
                          >
                            <input
                              type="email"
                              class="form-control border-radius-0"
                              placeholder="Company Email"
                              @blur="$v.formData.email.$touch()"
                              v-model.trim="formData.email"
                              required
                            />
                          </div>
                          <div
                            class="col-md-12 text-right"
                            v-if="$v.formData.email.$error"
                          >
                            <p
                              class="error_level"
                              v-if="!$v.formData.email.required"
                            >
                              Email field is required.
                            </p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.email.minLength"
                            >
                              At least 6 character email.
                            </p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.email.email"
                            >
                              Please enter valid email.
                            </p>
                          </div>
                        </div>
                        <div class="row pb-2">
                          <div class="col-md-5 text-right mr-0 pr-0">
                            <label>Contact Number</label>
                          </div>
                          <div
                            class="col-md-7"
                            :class="{ invalid: $v.formData.contact.$error }"
                          >
                            <input
                              type="text"
                              class="form-control border-radius-0"
                              placeholder="Contact Number"
                              @blur="$v.formData.contact.$touch()"
                              v-model.trim="formData.contact"
                              required
                            />
                          </div>
                          <div
                            class="col-md-12 text-right"
                            v-if="$v.formData.contact.$error"
                          >
                            <p
                              class="error_level"
                              v-if="!$v.formData.contact.required"
                            >
                              Contact field is required.
                            </p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.contact.minLength"
                            >
                              At least 7 character contact number.
                            </p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 text-right mr-0 pr-0">
                            <label>Workspace</label>
                          </div>
                          <div
                            class="col-md-7"
                            :class="{ invalid: $v.formData.workspace.$error }"
                          >
                            <input
                              type="text"
                              class="form-control border-radius-0"
                              placeholder="Workspace"
                              @blur="checkWorkspace"
                              @change="$v.formData.workspace.$touch()"
                              v-model.trim="formData.workspace"
                              required
                            />
                          </div>
                          <div
                            class="col-md-12 text-right"
                            v-if="$v.formData.workspace.$error"
                          >
                            <p
                              class="error_level"
                              v-if="!$v.formData.workspace.required"
                            >
                              Workspace field is required.
                            </p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.workspace.minLength"
                            >
                              At least 4 character workspace.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group">
                      <label class="group-label">Product Details</label>
                      <div class="group-attribute-container">
                        <div class="row pb-2">
                          <div class="col-md-12 text-justify mr-0 pr-0">
                            Do you want to verify from IRD?
                            <input
                              type="radio"
                              name="ird"
                              id="ird_yes"
                              value="1"
                              checked
                              v-model="formData.ird"
                            />
                            <label for="ird_yes">
                              <span class="ml-1">YES</span>
                            </label>
                            <input
                              type="radio"
                              class="ml-2"
                              name="ird"
                              id="ird_no"
                              value="0"
                              v-model="formData.ird"
                            />
                            <label for="ird_no">
                              <span class="ml-1">NO</span>
                            </label>
                          </div>
                        </div>
                        <div class="row pb-2">
                          <div class="col-md-5 text-right mr-0 pr-0">
                            <label>Plan</label>
                          </div>
                          <div
                            class="col-md-7"
                            :class="{ invalid: $v.formData.plan_id.$error }"
                          >
                            <select
                              class="form-control border-radius-0"
                              @change="planDetail"
                              @blur="$v.formData.plan_id.$touch()"
                              v-model.trim="formData.plan_id"
                              required
                            >
                              <option value>Select Your Plan</option>
                              <option
                                v-for="(plan, key) in dataLists1"
                                :value="plan.id"
                                :key="key"
                              >
                                {{ plan.title }}
                              </option>
                            </select>
                          </div>
                          <div
                            class="col-md-12 text-right"
                            v-if="$v.formData.plan_id.$error"
                          >
                            <p
                              class="error_level"
                              v-if="!$v.formData.plan_id.required"
                            >
                              Please choose your plan.
                            </p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.plan_id.numeric"
                            >
                              Invalid plan id.
                            </p>
                          </div>
                        </div>
                        <div class="row pb-2">
                          <div class="col-md-5 text-right mr-0 pr-0">
                            <label for="subscription_type"
                              >Subscription Type</label
                            >
                          </div>
                          <div
                            class="col-md-7"
                            :class="{
                              invalid: $v.formData.subscription.$error,
                            }"
                          >
                            <select
                              class="form-control border-radius-0"
                              id="subscription_type"
                              @blur="$v.formData.subscription.$touch()"
                              v-model.trim="formData.subscription"
                              required
                            >
                              <option value>Select Subscription Type</option>
                              <option
                                v-for="(subscription, index) in subscriptions"
                                :key="index"
                                :value="subscription.value"
                              >
                                {{ subscription.title }}
                              </option>
                            </select>
                          </div>
                          <div
                            class="col-md-12 text-right"
                            v-if="$v.formData.subscription.$error"
                          >
                            <p
                              class="error_level"
                              v-if="!$v.formData.subscription.required"
                            >
                              Please choose your subscription.
                            </p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.subscription.numeric"
                            >
                              Invalid subscription type.
                            </p>
                          </div>
                        </div>
                        <div class="row pb-2">
                          <div class="col-md-5 text-right mr-0 pr-0">
                            <label>Number Of Branch</label>
                          </div>
                          <div
                            class="col-md-7"
                            :class="{ invalid: $v.formData.branches.$error }"
                          >
                            <select
                              class="form-control border-radius-0"
                              @blur="$v.formData.branches.$touch()"
                              v-model.trim="formData.branches"
                              required
                            >
                              <option value>Select Branch No.</option>
                              <option
                                v-for="branch in 10"
                                :key="branch"
                                :value="branch"
                              >
                                {{ branch }}
                              </option>
                            </select>
                          </div>
                          <div
                            class="col-md-12 text-right"
                            v-if="$v.formData.branches.$error"
                          >
                            <p
                              class="error_level"
                              v-if="!$v.formData.branches.required"
                            >
                              Please choose no. of branch.
                            </p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.branches.numeric"
                            >
                              Invalid branch input.
                            </p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 text-right mr-0 pr-0">
                            <label>Number Of Users</label>
                          </div>
                          <div
                            class="col-md-7"
                            :class="{ invalid: $v.formData.users.$error }"
                          >
                            <select
                              class="form-control border-radius-0"
                              @blur="$v.formData.users.$touch()"
                              v-model.trim="formData.users"
                              required
                            >
                              <option value>Select Users No.</option>
                              <option
                                v-for="user in 20"
                                :key="user"
                                :value="user"
                              >
                                {{ user }}
                              </option>
                            </select>
                          </div>
                          <div
                            class="col-md-12 text-right"
                            v-if="$v.formData.users.$error"
                          >
                            <p
                              class="error_level"
                              v-if="!$v.formData.users.required"
                            >
                              Please choose no. of user.
                            </p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.users.numeric"
                            >
                              Invalid no. of user.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-3" v-if="paymentDetails.total > 0">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group">
                      <label class="group-label">Payment Details</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-12">
                            <table
                              class="
                                table table-responsive
                                payment-detail-table
                              "
                            >
                              <thead>
                                <tr>
                                  <th scope="col">S.N.</th>
                                  <th scope="col" style="width: 50.7%">
                                    Particular
                                  </th>
                                  <th scope="col">Qty</th>
                                  <th scope="col">Rate</th>
                                  <th scope="col">Amount (NRP)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-if="plan.price">
                                  <th scope="row">1</th>
                                  <td>{{ plan.title }}</td>
                                  <td class="text-center">1</td>
                                  <td class="text-center">{{ plan.price }}</td>
                                  <td class="text-center">{{ plan.price }}</td>
                                </tr>
                                <tr v-if="paymentDetails.extra_branch > 0">
                                  <th scope="row">2</th>
                                  <td>Extra branches added</td>
                                  <td class="text-center">
                                    {{ paymentDetails.extra_branch }}
                                  </td>
                                  <td class="text-center">
                                    {{ plan.extra_branch_cost.price }}
                                  </td>
                                  <td class="text-center">
                                    {{ paymentDetails.extra_branch_cost }}
                                  </td>
                                </tr>
                                <tr v-if="paymentDetails.extra_user > 0">
                                  <th scope="row">3</th>
                                  <td>Extra users added</td>
                                  <td class="text-center">
                                    {{ paymentDetails.extra_user }}
                                  </td>
                                  <td class="text-center">
                                    {{ plan.extra_user_cost.price }}
                                  </td>
                                  <td class="text-center">
                                    {{ paymentDetails.extra_user_cost }}
                                  </td>
                                </tr>
                                <tr class="no-border">
                                  <th rowspan="5" colspan="2"></th>
                                  <td colspan="2" class="text-right">Total</td>
                                  <td class="text-center">
                                    {{ paymentDetails.total.toFixed(2) }}
                                  </td>
                                </tr>
                                <tr class="no-border">
                                  <td colspan="2" class="text-right">
                                    X {{ formData.subscription }} Month
                                  </td>
                                  <td class="text-center">
                                    {{ paymentDetails.total_cost.toFixed(2) }}
                                  </td>
                                </tr>
                                <tr class="no-border">
                                  <td colspan="2" class="text-right">
                                    Discount
                                  </td>
                                  <td class="text-center">
                                    {{
                                      paymentDetails.discount_amount.toFixed(2)
                                    }}
                                  </td>
                                </tr>
                                <tr class="no-border">
                                  <td
                                    colspan="2"
                                    class="text-right"
                                    style="width: 23.3%"
                                  >
                                    Vat able Amount
                                  </td>
                                  <td class="text-center">
                                    {{
                                      paymentDetails.vatable_amount.toFixed(2)
                                    }}
                                  </td>
                                </tr>
                                <tr class="no-border">
                                  <td colspan="2" class="text-right">
                                    13 % Vat
                                  </td>
                                  <td class="text-center">
                                    {{ paymentDetails.vat.toFixed(2) }}
                                  </td>
                                </tr>
                                <tr class="no-border">
                                  <td></td>
                                  <td>
                                    Promo Code
                                    <input
                                      type="text"
                                      placeholder="Code"
                                      @blur="checkPromocode"
                                      v-model.trim="formData.promo_code"
                                      style="width: 150px"
                                    />
                                  </td>
                                  <td colspan="2" class="text-right">
                                    Payable Amount
                                  </td>
                                  <td class="text-center">
                                    {{
                                      paymentDetails.payable_amount.toFixed(2)
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <h6>Select Payment Method</h6>
                        <div class="row pt-3 wallet">
                          <div class="col-md-2">
                            <img
                              :src="`${fileLocation}icon/wallet/esewa.jpg`"
                              @click="formData.paymentmethod_id = 1"
                              class="img-fluid"
                            />
                          </div>
                          <div class="col-md-2">
                            <img
                              :src="`${fileLocation}icon/wallet/nic-asia-bank.png`"
                              @click="formData.paymentmethod_id = 2"
                              class="img-fluid"
                            />
                          </div>
                          <div class="col-md-2">
                            <img
                              :src="`${fileLocation}icon/wallet/khalti-digital-wallet.png`"
                              @click="formData.paymentmethod_id = 3"
                              class="img-fluid"
                            />
                          </div>
                          <div class="col-md-2">
                            <img
                              :src="`${fileLocation}icon/wallet/ime-pay.jpg`"
                              class="img-fluid"
                              @click="formData.paymentmethod_id = 4"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary">
                  Submit
                </button>
                <p
                  :class="
                    eventMessage.indexOf('success') >= 0
                      ? 'success_level'
                      : 'error_level'
                  "
                >
                  {{ eventMessage }}
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
/* eslint-disable */
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  computed: {
    ...mapGetters([
      "apiRoot",
      "token",
      "processing",
      "fileLocation",
      "eventMessage",
      "dataLists1",
      "dataLists2",
      "modalId",
      "modalState",
    ]),
  },
  mounted() {
    this.$store.commit("getData1", "api/plans");
    this.getSubscriptions();
  },
  data() {
    return {
      paymentDetails: {
        extra_user: 0,
        extra_user_cost: 0,
        extra_branch: 0,
        extra_branch_cost: 0,
        total: 0,
        total_cost: 0,
        subscription_discount: 0,
        subscription_discount_type: 1,
        promo_discount: 0,
        promo_discount_type: 1,
        discount_amount: 0,
        vatable_amount: 0,
        vat: 0,
        payable_amount: 0,
      },
      plan: [],
      subscriptions: [],
      formData: {
        company: "",
        address: "",
        email: "",
        contact: "",
        pan: "",
        ird: 1,
        plan_id: "",
        workspace: "",
        subscription: "",
        branches: "",
        users: "",
        promo_code: "",
        paymentmethod_id: "",
      },
    };
  },
  validations: {
    formData: {
      company: { required, minLength: minLength(2), maxLength: maxLength(255) },
      address: { required, minLength: minLength(2), maxLength: maxLength(255) },
      email: { required, email, minLength: minLength(6) },
      contact: { required, numeric, minLength: minLength(7) },
      pan: {
        required,
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(9),
      },
      plan_id: { required, numeric },
      workspace: { required, minLength: minLength(4) },
      subscription: { required, numeric },
      branches: { required, numeric },
      users: { required, numeric },
    },
  },
  methods: {
    checkWorkspace() {
      if (this.formData.workspace) {
        this.$store.commit(
          "getData2",
          `api/workspace/${this.formData.workspace}`
        );
      }
    },
    checkPromocode() {
      if (this.formData.promo_code) {
        axios
          .get(`${this.apiRoot}api/promo/${this.formData.promo_code}`, {
            headers: { Authorization: `Bearer ${this.token}` },
          })
          .then((res) => {
            if (res.data.success_message) {
              this.paymentDetails.promo_discount = res.data.data.discount;
              this.paymentDetails.promo_discount_type = res.data.data.type;
            } else {
              this.$store.commit("setEventMessage", res.data.error_message);
              this.paymentDetails.promo_discount = 0;
              this.paymentDetails.promo_discount_type = 1;
            }
            this.calculatePayment();
          })
          .catch((error) => {
            this.$store.commit("manageErrors", error);
          });
      }
    },
    planDetail() {
      if (this.formData.plan_id) {
        axios
          .get(`${this.apiRoot}api/plan/${this.formData.plan_id}`, {
            headers: { Authorization: `Bearer ${this.token}` },
          })
          .then((res) => {
            this.plan = res.data;
          })
          .catch((error) => {
            this.$store.commit("manageErrors", error);
          });
      }
    },
    addOrder() {
      this.$store.commit("setApiUrl", "api/order/store");
      this.$store.commit("addData", {
        ...this.paymentDetails,
        ...this.formData,
      });
    },
    calculatePayment() {
      let disc1 = 0;
      let disc2 = 0;
      this.paymentDetails.total =
        this.plan.price +
        this.paymentDetails.extra_branch_cost +
        this.paymentDetails.extra_user_cost;
      if (this.formData.subscription > 0) {
        this.paymentDetails.total_cost =
          this.paymentDetails.total * this.formData.subscription;
      } else {
        this.paymentDetails.total_cost = this.paymentDetails.total;
      }
      if (this.paymentDetails.subscription_discount_type == 1) {
        disc1 = this.paymentDetails.subscription_discount;
      } else {
        disc1 =
          (this.paymentDetails.total_cost *
            this.paymentDetails.subscription_discount) /
          100;
      }
      if (this.paymentDetails.promo_discount_type == 1) {
        disc2 = this.paymentDetails.promo_discount;
      } else {
        disc2 =
          ((this.paymentDetails.total_cost - disc1) *
            this.paymentDetails.promo_discount) /
          100;
      }
      this.paymentDetails.discount_amount = disc1 + disc2;
      this.paymentDetails.vatable_amount =
        this.paymentDetails.total_cost - this.paymentDetails.discount_amount;
      this.paymentDetails.vat = (this.paymentDetails.vatable_amount * 13) / 100;
      this.paymentDetails.payable_amount =
        this.paymentDetails.total_cost - this.paymentDetails.vat;
    },
    getSubscriptions() {
      axios
        .get(`${this.apiRoot}api/subscriptions`, {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((res) => {
          this.subscriptions = res.data;
        })
        .catch((error) => {
          this.$store.commit("manageErrors", error);
        });
    },
  },
  watch: {
    "formData.workspace"(value) {
      if (value) {
        this.formData.workspace = value
          .toLowerCase()
          .trim()
          .split(".")
          .join("")
          .replace(/[\s\/]/g, "");
      } else {
        this.error = "";
      }
    },
    eventMessage(value) {
      if (value && value.indexOf("success") >= 0) {
        this.formData.company = "";
        this.formData.address = "";
        this.formData.email = "";
        this.formData.contact = "";
        this.formData.pan = "";
        this.formData.plan_id = "";
        this.formData.workspace = "";
      }
    },
    "formData.subscription"(value) {
      if (value) {
        let disc = this.subscriptions.find(function (data) {
          return data.value == value;
        });
        this.paymentDetails.subscription_discount = disc.discount;
        this.paymentDetails.subscription_discount_type = disc.type;
      }
      this.calculatePayment();
    },
    plan() {
      this.calculatePayment();
    },
    "formData.branches"() {
      if (this.formData.branches > this.plan.branches) {
        this.paymentDetails.extra_branch =
          this.formData.branches - this.plan.branches;
        this.paymentDetails.extra_branch_cost =
          this.paymentDetails.extra_branch * this.plan.extra_branch_cost.price;
      } else {
        this.paymentDetails.extra_branch = 0;
        this.paymentDetails.extra_branch_cost = 0;
      }
      this.calculatePayment();
    },
    "formData.users"() {
      if (this.formData.users > this.plan.users) {
        this.paymentDetails.extra_user = this.formData.users - this.plan.users;
        this.paymentDetails.extra_user_cost =
          this.paymentDetails.extra_user * this.plan.extra_user_cost.price;
      } else {
        this.paymentDetails.extra_user = 0;
        this.paymentDetails.extra_user_cost = 0;
      }
      this.calculatePayment();
    },
  },
};
</script>