<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId=='orderDetails'"
      :class="modalState?'show':''"
      tabindex="-1"
      role="dialog"
      style="display: block;"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{details.company}}
              <small>( workspace : {{details.workspace}} )</small>
            </h5>
            <a class="close" @click="$store.dispatch('modalClose')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <div class="bg-theam-secondary">
            <div class="modal-body row">
              <div class="col-md-6 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group">
                      <label class="group-label">Company Detail</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Company Name</div>
                          <div class="col-md-7 ml-0 pl-0">{{details.company }}</div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">PAN / VAT Number</div>
                          <div class="col-md-7 ml-0 pl-0">{{details.pan}}</div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Company Address</div>
                          <div class="col-md-7 ml-0 pl-0">{{details.address}}</div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Company Email</div>
                          <div class="col-md-7 ml-0 pl-0">{{details.email}}</div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Contact Number</div>
                          <div class="col-md-7 ml-0 pl-0">{{details.contact}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group">
                      <label class="group-label">Product Detail</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Plan</div>
                          <div class="col-md-7 ml-0 pl-0">{{details.plan.title}}</div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Subscription Time</div>
                          <div class="col-md-7 ml-0 pl-0">{{details.subscription}} Months</div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Number Of Branch</div>
                          <div class="col-md-7 ml-0 pl-0">{{details.branches}}</div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Number Of Users</div>
                          <div class="col-md-7 ml-0 pl-0">{{details.users}}</div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">User</div>
                          <div class="col-md-7 ml-0 pl-0">{{details.user.name}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-3">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group">
                      <label class="group-label">Payment Details</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="row mb-2">
                              <div class="col-md-5 mr-0 pr-0">Payment Method</div>
                              <div
                                class="col-md-7 ml-0 pl-0"
                              >{{details.payment_details.payment_method.title}}</div>
                            </div>
                            <div class="row mb-2">
                              <div class="col-md-5 mr-0 pr-0">Discount</div>
                              <div
                                class="col-md-7 ml-0 pl-0"
                              >Rs. {{details.payment_details.bills.discount}}</div>
                            </div>
                            <div class="row mb-2">
                              <div class="col-md-5 mr-0 pr-0">Vatable Amount</div>
                              <div
                                class="col-md-7 ml-0 pl-0"
                              >Rs. {{details.payment_details.bills.vatable_amount}}</div>
                            </div>
                            <div class="row mb-2">
                              <div class="col-md-5 mr-0 pr-0">VAT Amount</div>
                              <div
                                class="col-md-7 ml-0 pl-0"
                              >Rs. {{details.payment_details.bills.vat_amount}}</div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row mb-2">
                              <div class="col-md-5 mr-0 pr-0">Amount Payable</div>
                              <div
                                class="col-md-7 ml-0 pl-0"
                              >Rs. {{details.payment_details.bills.payable_amount}}</div>
                            </div>
                            <div class="row mb-2">
                              <div class="col-md-5 mr-0 pr-0">Paid Amount</div>
                              <div
                                class="col-md-7 ml-0 pl-0"
                              >Rs. {{details.payment_details.bills.paid_amount}}</div>
                            </div>
                            <div class="row mb-2">
                              <div class="col-md-5 mr-0 pr-0">Due Amount</div>
                              <div
                                class="col-md-7 ml-0 pl-0"
                              >Rs. {{details.payment_details.bills.due_amount}}</div>
                            </div>
                            <div class="row mb-2">
                              <div class="col-md-5 mr-0 pr-0">Total Amount</div>
                              <div
                                class="col-md-7 ml-0 pl-0"
                              >Rs. {{details.payment_details.bills.total}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="text-right mt-3">
                      <button
                        class="btn btn-primary btn-sm mr-1"
                        @click="submit('approved',1)"
                        v-if="$route.params.state=='pending' || $route.params.state=='suspended'"
                      >Approve</button>
                      <button
                        class="btn btn-warning btn-sm mx-1"
                        @click="submit('rejected',2)"
                        v-if="$route.params.state=='pending'"
                      >Reject</button>
                      <button
                        class="btn btn-primary btn-sm mx-1"
                        v-if="$route.params.state=='approved' || $route.params.state=='suspended'"
                      >Renew</button>
                      <button
                        class="btn btn-success btn-sm mx-1"
                        @click="$store.dispatch('modalOpen','editOrder')"
                        v-if="$route.params.state=='approved'"
                      >Update</button>
                      <button
                        class="btn btn-warning btn-sm mx-1"
                        @click="submit('suspended',3)"
                        v-if="$route.params.state=='approved'"
                      >Suspend</button>
                      <button
                        class="btn btn-danger btn-sm ml-1"
                        @click="$store.dispatch('modalClose')"
                      >Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* disable eslint */
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      details: [],
    };
  },
  methods: {
    submit(status, value) {
      this.$store.commit("setApiUrl", "api/order/status");
      this.$store.commit("addData", {
        id: this.details.id,
        status: status,
        value: value,
      });
    },
    createDatabase() {
      this.$store.dispatch("enableMessage");
      this.$store.commit(
        "setEventMessage",
        "Please wait for response. Database creating...."
      );
      this.$store.commit(
        "getData",
        `api/create/database/${this.details.workspace}`
      );
    },
    cloneDatabase() {
      this.$store.dispatch("enableMessage");
      this.$store.commit(
        "setEventMessage",
        "Please wait for response. Database schema creating...."
      );
      this.$store.commit(
        "getData",
        `api/clone/database/${this.details.workspace}`
      );
    },
    updateUser() {
      axios
        .post(`${this.apiRoot}api/create/database/user`, {
          workspace: this.details.workspace,
        })
        .then((res) => {
          this.$store.dispatch("enableMessage");
          this.$store.commit("setEventMessage", res.data.message);
        })
        .catch((error) => {
          this.$store.commit("manageErrors", error);
        });
    },
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataId",
      "modalId",
      "modalState",
      "apiRoot"
    ]),
  },
  watch: {
    dataId(value) {
      this.details = this.dataLists.find(function (data) {
        return data.id == value;
      });
    },
    eventMessage(value) {
      if (value.indexOf("approved success") >= 0) {
        this.createDatabase();
      } else if (value.indexOf("Database created success") >= 0) {
        this.cloneDatabase();
      } else if (value.indexOf("schema created success") >= 0) {
        this.updateUser();
      } else if (value.indexOf("success") >= 0) {
        this.$store.commit("setRemoveList", this.details.id);
      }
    },
  },
};
</script>